<template>
  <fr-button
    variant="ghost"
    aria-label="Menu"
    :icon="showDrawer ? 'fas fa-minus' : 'menu'"
    @click="showDrawer = !showDrawer"
  />
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'

const authStore = useAuthStore()

const showDrawer = computed({
  get: () => authStore.showDrawer,
  set: (value: boolean) => (authStore.showDrawer = value)
})
</script>
